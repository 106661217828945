<template>
    <div class="HomeChileView">
      <v-container fluid>
        <v-row>
          <v-col class="pa-0">
            <SliderBannerComponent :images="bannerImages" height="40vh" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <MarketStoreComponent class="my-10" message="Accede a los <B>Beneficios Descargando</B> la App"  />
          </v-col>
        </v-row>
        <v-row v-if="false" style="background-color: #fafafa;">
          <v-col align="center" align-self="center" class="" cols="12" md="6">
            <SliderBannerTextComponent :images="bannerImagesText" size="500px" />
          </v-col>
          <v-col align="center" align-self="center" class="" cols="6" md="3">
            <PopImageComponent :image="require('@/assets/promos/rep800x1190.png')" message="PARTNER DESTACADO" :square="false" :mode="$vuetify.breakpoint.mdAndUp ? 'auto' : '300'" />
          </v-col>
          <v-col align="center" align-self="center" class="" cols="6" md="3">
            <PopImageComponent :image="require('@/assets/promos/agro-800x1190.png')" message="PARTNER DESTACADO" :square="false" :mode="$vuetify.breakpoint.mdAndUp ? 'auto' : '300'" />
          </v-col>
        </v-row>
        <v-row v-if="false" class="py-10">
          <v-col align-self="center" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'" cols="12" sm="6">
            <v-btn elevation="0" color="customColor" dark class="px-5 py-5" x-small to="chile/buy-points">Compra Puntos</v-btn>
          </v-col>
          <v-col align-self="center" :align="$vuetify.breakpoint.smAndUp ? 'left' : 'center'" cols="12" sm="6">
            <v-btn elevation="0" color="customColor" outlined dark class="px-5 py-5" x-small to="chile/events">Canjear Puntos</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="true" style="background-color: #fafafa;" class="py-10">
          <v-col class="pa-0" align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Events
            </div>
            <EventsListComponent :events="$store.state.events.filter(item => item.country === 'CL' && item.state === 'active')" :max="15" :key="$store.state.events.length" />
          </v-col>
        </v-row>
        <v-row v-if="true" class="py-10">>
          <v-col align-self="center" align="center">
            <SingleBannerComponent align-self="center" class="py-3 px-md-15 px-5" :image="singleBannerImage01" />
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Promociones Destacadas
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.shops.filter(item => item.country === 'CL')" />
            <div class="py-15">
              <v-btn elevation="0" color="customColor" outlined dark class="px-5 py-5" x-small to="/chile/shops">Ver más</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Travel
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.travel.filter(item => item.country === 'CL')" />
            <div class="py-15">
              <v-btn elevation="0" color="customColor" outlined dark class="px-5 py-5" x-small to="/chile/promotions">Ver más</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Sports
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.sports.filter(item => item.country === 'CL')" />
            <div class="py-15">
              <v-btn elevation="0" color="customColor" outlined dark class="px-5 py-5" x-small to="/chile/sports">Ver más</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="true" style="background-color: #fafafa;" class="py-10">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Membresías Destacadas
            </div>
            <ShowcaseComponent :items="$store.state.memberships.filter(item => item.country === 'CL')" />
          </v-col>
        </v-row>
        <v-row v-if="false" style="background-color: #fafafa;">
          <v-col align-self="center" align="center">
            <SingleBannerComponent class="px-md-15 px-5 pt-0" :image="singleBannerImage02" />
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Alojamientos Destacados
            </div>
            <AccommodationComponent class="px-md-15 px-5" :items="$store.state.accommodation.filter(item => item.country === 'CL')" />
          </v-col>
        </v-row>
        <v-row v-if="false" style="background-color: white;">
          <v-col align-self="center" align="center">
            <SingleBannerComponent class="px-md-15 px-5 py-5" :image="singleBannerImage01" />
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col class="px-0">
            <SingleButtonParallaxComponent message="Compra tus puntos Passpoint y canjea tus Beneficios" button="Comprar Puntos" link="/chile/buy-points" :background="require('@/assets/backgrounds/dominga.png')" />
          </v-col>
        </v-row>
        <v-row  class="py-10">>
          <v-col>
            <BrandingSliderComponent class="my-10 py-10" :images="merchants" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_HomeChileView.scss'
import SliderBannerComponent from '@/components/SliderBannerComponent/SliderBannerComponent.vue'
import AccommodationComponent from '@/components/AccommodationComponent/AccommodationComponent.vue'
import EventsListComponent from '@/components/EventsListComponent/EventsListComponent.vue'
import PopImageComponent from '@/components/PopImageComponent/PopImageComponent.vue'
import SliderBannerTextComponent from '@/components/SliderBannerTextComponent/SliderBannerTextComponent.vue'
import ShowcaseComponent from '@/components/ShowcaseComponent/ShowcaseComponent.vue'
import SingleButtonParallaxComponent from '@/components/SingleButtonParallaxComponent/SingleButtonParallaxComponent.vue'
import BrandingSliderComponent from '@/components/BrandingSliderComponent/BrandingSliderComponent.vue'
import SingleBannerComponent from '@/components/SingleBannerComponent/SingleBannerComponent.vue'
import GiftCardListComponent from '@/components/GiftCardListComponent/GiftCardListComponent.vue'
import MarketStoreComponent from '@/components/MarketStoreComponent/MarketStoreComponent.vue'

export default {
  name: 'HomeChileView',
  components: { MarketStoreComponent, AccommodationComponent, GiftCardListComponent, BrandingSliderComponent, SingleButtonParallaxComponent, ShowcaseComponent, SliderBannerTextComponent, PopImageComponent, SliderBannerComponent, EventsListComponent, SingleBannerComponent },
  data () {
    return {
      merchants: [
        require('@/assets/merchants/partner-01.png'),
        require('@/assets/merchants/partner-02.png'),
        require('@/assets/merchants/partner-03.png'),
        require('@/assets/merchants/partner-04.png'),
        require('@/assets/merchants/partner-05.png'),
        require('@/assets/merchants/partner-07.png'),
        require('@/assets/merchants/partner-08.png')
      ],
      bannerImagesText: [
        {
          image: require('@/assets/promos/promo01.png'),
          name: 'Club Berlin',
          text: '',
          url: '/chile/m/berlin-club'
        },
        {
          image: require('@/assets/promos/promo02.png'),
          name: 'Dominga Bistró',
          text: '',
          url: '/chile/m/dominga'
        },
        {
          image: require('@/assets/promos/promo04.png'),
          name: 'Patio Bar',
          text: '',
          url: '/chile/m/patiobar'
        },
        {
          image: require('@/assets/promos/promo03.png'),
          name: 'Nerolí Studio 21',
          text: '',
          url: '/chile/m/neroli'
        }
      ],
      bannerImages: [
        require('@/assets/banners/banners-01.png'),
        require('@/assets/banners/banners-02.png'),
        require('@/assets/banners/banners-03.png'),
        require('@/assets/banners/banner_skydome.png')
      ],
      singleBannerImage01: require('@/assets/banners/banner_pay_02.png'),
      singleBannerImage02: require('@/assets/banners/banner_pay_02.jpg')
    }
  }
}
</script>
